<template>
  <div class="imageBackground">
    <div class="contentContainer">
      <div class="headerContainer">
        <img :src="logo" class="logo" />
      </div>
      <div class="mainContainer">
        <div class="subContainer">
          <h1 class="title">
            {{ $t("labels.recoverMyPassword") }}
          </h1>
          <div class="inputRow">
            <custom-input
              type="email"
              :modelValue="email"
              :modelInvalid="invalidEmail"
              @update:modelValue="email = $event"
              @update:modelInvalid="invalidEmail = $event"
              :placeholder="`* ${$t('labels.e_mail')}`"
            />
          </div>
          <button class="authBtnContainer bg-yellow" @click="resetPassword()">
            {{ $t("labels.submit") }}
          </button>
          <div class="termsRow">
            <p class="termsDes">
              {{ $t("labels.rememberPassword") }}
              <span class="termsBtn no-border" @click="goToLogin()">{{
                $t("labels.return")
              }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import CustomInput from "../components/CustomInput.vue";
export default {
  name: "ForgotPasswordPage",
  components: {
    CustomInput,
  },
  data() {
    return {
      logo: require("../assets/image/svg/logo.svg"),
      email: "",
      invalidEmail: false,
    };
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    goToLogin() {
      this.$router.push("/login");
    },
    resetPassword() {
      if (!this.email) {
        this.invalidEmail = true;
        return;
      }
      this.$store.dispatch("auth/resetPasswordAttempt", this.email);
    },
  },
  //   watch: {
  //     name: {
  //       handler(newVal, oldVal) {
  //         console.log(newVal);
  //         console.log(oldVal);
  //       },
  //       immediate: true,
  //     },
  //   },
};
</script>

<style scoped>
.termsRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.termsDes {
  font-size: 13px;
  color: var(--textColor);
}
.termsBtn {
  color: var(--primary);
  border-bottom: 1px solid var(--primary);
  font-size: 15px;
  margin-left: 5px;
  cursor: pointer;
}
.no-border {
  border: none;
}
</style>